/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import lore from "assets/img/profile/lore_square.jpg";

import profilePageStyle from "assets/jss/material-kit-pro-react/pages/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function ProfileLore() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <div className={classes.profile}>
            <div>
              <img src={lore} alt="..." className={imageClasses} />
            </div>
            <div className={classes.name}>
              <h3 className={classes.title}>Lore Van De Kelft</h3>
              <h6>Behandelende logopediste en Stottertherapeute</h6>
            </div>
          </div>
        </GridItem>
      </GridContainer>
      <div className={classes.profileTabs}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            className={classes.gridItem}>
            <h4 className={classes.title}>Achtergrond</h4>
            <p>
              Lore studeerde in 2023 af aan de KULeuven. Sindsdien is ze tewerkgesteld in de praktijk, waar ze zich voornamelijk richt op het behandelen van kinderen met leer-, taal- en articulatiemoeilijkheden. In het academiejaar 2023-2024 volgde ze een specialisatie van CIOOS (Centrum voor Informatie, Onderzoek en Opleiding over Stotteren) tot stottertherapeut. Hierdoor kunnen kleuters, kinderen en (jong)volwassenen met stotterproblemen ook bij haar terecht. Naast haar job in de praktijk, werkt Lore halftijds in het buitengewoon onderwijs type 3, type 9 en type BA (BuBaO Elzenhof, Aarschot). 
            </p>
            <p>
              Lore vindt het belangrijk om haar therapieën af te stemmen aan de specifieke noden van elke cliënt. Om haar behandelingen wetenschappelijk te onderbouwen, neemt ze op regelmatige basis deel aan bijscholingen, congressen en studiedagen.
            </p>
            </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={5}
            className={classes.gridItem}>
            <h4 className={classes.title}>Vakgebieden</h4>
            <ul className={classes.job}>
              <li>
                <a href='doelgroepen#articulatie'>Articulatiestoornissen</a>
              </li>
              <li>
                <a href='doelgroepen#taal'>Taalstoornissen</a>
              </li>
              <li>
                <a href='doelgroepen#stotteren'>Stotteren</a>
              </li>
              <li>
                <a href='doelgroepen#leerstoornissen'>Leerstoornissen</a>
              </li>
            </ul>
            <hr />
            <h4 className={classes.title}>Opleidingen</h4>
            <ul>
              <li>Master in de logopedische en audiologische wetenschappen (KU Leuven, 2023)</li>
              <li>Specialisatie tot stottertherapeut (CIOOS, Universiteit Antwerpen, 2024)</li>
              <li>RekenTrapperS Eureka Leuven</li>
              <li>Jaarlijkse congressen, studiedagen en bijscholingen</li>
            </ul>
            <hr />
          </GridItem>
        </GridContainer>

      </div>
      <Clearfix />
    </div>
    </div>
  );
}
