import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionMutisme({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section " + classes.sectionDark} {...rest} >
      <div className={classes.container}>
        <div className={classes.targetContent}>
        <h2 className={classes.titleWhite}>Selectief mutisme</h2>
        <GridContainer className={classes.descriptionWhite}>
          <GridItem md={4} sm={4}>
            <h3 className={classes.title}>Samengevat</h3>
            <p>
              Selectief mutisme is een angststoornis waarbij het kind in bepaalde sociale situaties niet praat.
            </p>
          </GridItem>

          <GridItem md={8} sm={8}>
            <h3 className={classes.title}></h3>
            <h4 className={classes.title}>Wat is Selectief mutisme?</h4>
            <p>
            Selectief mutisme is een erkende angststoornis waarbij het kind een extreme spreekangst heeft. Kinderen met selectief mutisme zwijgen in bepaalde sociale situaties, zoals op school of in contact met volwassenen. Dit kan in contrast staan met de situatie thuis, waar ze vlotte praters zijn. Selectief mutisme komt voor bij 7 op 1000 kinderen. Het verschijnt vaak tussen de leeftijd van 3 en 5 jaar. Meisjes hebben vaker selectief mutisme dan jongens.
            Meer informatie voor ouders en leerkrachten is te vinden op de website van de Vereniging Selectief Mutisme: <a href="https://selectiefmutisme.be" target="_blank">selectiefmutisme.be</a>.


            </p>
            <h4 className={classes.title}>Therapie</h4>
            <p>
              Cognitieve gedragstherapie kan helpen om de angst om te spreken te overwinnen.
            </p>

          </GridItem>

          <GridItem md={12} sm={12}>
            <h3 className={classes.title}>Therapeuten</h3>
            <p>
              Voor selectief mutisme zal u onderzocht en behandeld worden door <a href="team#elke"><b>Elke Ven</b></a>.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  </div>
  )
};
