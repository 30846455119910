/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Home from "@material-ui/icons/Home";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import InsertLink from "@material-ui/icons/InsertLink";
import Security from "@material-ui/icons/Security";

import Articulation from "@material-ui/icons/RecordVoiceOver"
import OMFT from "@material-ui/icons/RecordVoiceOver"
import Language from "@material-ui/icons/RecordVoiceOver"
import Learn from "@material-ui/icons/School"
import Stutter from "@material-ui/icons/RecordVoiceOver"
import Clutter from "@material-ui/icons/RecordVoiceOver"
import Mutisme from "@material-ui/icons/RecordVoiceOver"
import Neuro from "@material-ui/icons/RecordVoiceOver"
import ADHD from "@material-ui/icons/School"
import Parents from "@material-ui/icons/SupervisorAccount"
import ViewDay from "@material-ui/icons/ViewDay";
import AttachMoney from "@material-ui/icons/EuroSymbol";
import LocationOn from "@material-ui/icons/LocationOn";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const smoothScroll = (e, target) => {
    console.log(target)
    if (window.location.pathname === "/doelgroepen") {
      var isMobile = navigator.userAgent.match(
        /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
      );
      if (isMobile) {
        // if we are on mobile device the scroll into view will be managed by the browser
      } else {
        e.preventDefault();
        var targetScroll = document.getElementById(target);
        if (targetScroll){
          scrollGo(document.documentElement, targetScroll.offsetTop+250, 1250);
        }
      }
    }
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <Button
          href="/"
          className={classes.navLink}
          color="transparent"> <Home /> Home
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Team"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={People}
          dropdownList={[
            <Link to="/team#lieve"
                  className={classes.dropdownLink}>
              <Person className={classes.dropdownIcons} /> Lieve Mutton
            </Link>,
            <Link to="/team#elke"
                  className={classes.dropdownLink}>
              <Person className={classes.dropdownIcons} /> Elke Ven
            </Link>,
            <Link to="/team#madou"
                  className={classes.dropdownLink}>
              <Person className={classes.dropdownIcons} /> Madou Parys
            </Link>,
            <Link to="/team#lore"
                  className={classes.dropdownLink}>
              <Person className={classes.dropdownIcons} /> Lore Van De Kelft
            </Link>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Doelgroepen"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={ViewDay}
          dropdownList={[
            <Link
              to="/doelgroepen#articulatie"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "articulatie")}
            >
              <Articulation className={classes.dropdownIcons} /> Articulatiestoornissen
            </Link>,
            <Link
              to="/doelgroepen#omft"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "omft")}
            >
              <OMFT className={classes.dropdownIcons} /> Afwijkende mondgewoonten
            </Link>,
            <Link
              to="/doelgroepen#taal"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "taal")}
            >
              <Language className={classes.dropdownIcons} /> Taalstoornissen
            </Link>,
            <Link
              to="/doelgroepen#stotteren"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "stotteren")}
            >
              <Stutter className={classes.dropdownIcons} /> Stotteren
            </Link>,
            <Link
              to="/doelgroepen#broddelen"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "broddelen")}
            >
              <Clutter className={classes.dropdownIcons} /> Broddelen
            </Link>,
            <Link
              to="/doelgroepen#mutisme"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "mutisme")}
            >
              <Mutisme className={classes.dropdownIcons} /> Selectief mutisme
            </Link>,
            <Link
              to="/doelgroepen#neuro"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "neuro")}
            >
              <Neuro className={classes.dropdownIcons} /> Neurogene taal-spraakstoornissen
            </Link>,
            <Link
              to="/doelgroepen#leerstoornissen"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "leerstoornissen")}
            >
              <Learn className={classes.dropdownIcons} /> Leerstoornissen
            </Link>,
            <Link
              to="/doelgroepen#autisme"
              className={classes.dropdownLink}
              onClick={e => smoothScroll(e, "autisme")}
            >
              <ADHD className={classes.dropdownIcons} /> Autismestoornissen
            </Link>

          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/tarieven"
          className={classes.navLink}
          color="transparent"> <AttachMoney /> Tarieven
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/contact"
          className={classes.navLink}
          color="transparent"> <LocationOn /> Afspraak
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <Button
          href="/links"
          className={classes.navLink}
          color="transparent"> <InsertLink /> Links
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/gpdr"
          className={classes.navLink}
          color="transparent"> <Security /> GDPR
        </Button>
      </ListItem>*/}
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};
