import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionStutter({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section " + classes.sectionDark} {...rest} >
      <div className={classes.targetContent}>
      <div className={classes.container}>
        <h2 className={classes.titleWhite}>Stotteren</h2>
          <GridContainer className={classes.descriptionWhite}>
            <GridItem md={4} sm={4}>
              <h3 className={classes.title}>Samengevat</h3>
              <p>
                Stotteren zijn herhalingen, verlengingen en blokkeringen in de spraak. Deze onvloeiendheden komen onvrijwillig en ongewild voor. Het stotteren kan gepaard gaan met onaangename gevoelens, zoals spanning. De persoon die stottert kan op het stotteren reageren door het spreken te vermijden, uit te stellen, pogingen te doen om vlot te starten of het stottermoment eruit te duwen. Deze secundaire gedragingen zijn een teken dat het stotteren reeds negatief ontwikkeld is. Al op heel jonge leeftijd kan therapie voor stotteren gegeven worden. Het is dan ook aangeraden om zo snel mogelijk contact op te nemen met een stottertherapeut als je kind stottert, om te voorkomen dat het een ernstig probleem wordt, zowel in de vlotheid van het spreken als psychisch, sociaal en emotioneel.
              </p>
            </GridItem>

            <GridItem md={8} sm={8}>
              <h3 className={classes.title}></h3>
              <h4 className={classes.title}>Wat is stotteren?</h4>
              <p>
                Stotteren ontstaat meestal op jonge leeftijd.
                De helft van de mensen die ooit zullen stotteren, stotteren al voor de leeftijd van 3 jaar.
                Ook op deze jonge leeftijd is behandeling mogelijk en nodig.
                Stotteren is een complexe stoornis waarbij genetische factoren, omgevingsfactoren en kenmerken bij het kind een rol spelen.
                Er zijn 3 soorten stotteronvloeiendheden of kernstotters:
              </p>
              <p>
                <ul>
                  <li><b>Herhalingen </b>van:<br/>
                  <ul>
                    <li>
                      klanken: i-i-i-ik,  m-m-m-m-mama
                    </li>
                    <li>
                      lettergrepen: va-va-va-vakantie, pa-pa-pa-pa-papa
                    </li>
                    <li>
                      woorden die uit 1 lettergreep bestaan:  maar maar maar maar, ik ik ik ik
                    </li>
                  </ul>
                  </li>

                    <li><b>Verlengingen</b> van klanken: mmmmmama, iiiiik, vvvvvvvakantie</li>
                    <li><b>Blokkeringen</b>: i.k, d.at, b.eer</li>
                </ul>
              </p>
              <p>
                Men spreekt van stotteren van zodra iemand deze kernstotters in 3% van de woorden vertoont. Ook de duur van de stottermomenten onderscheiden kinderen die stotteren van kinderen die niet stotteren. Tot slot gaan de onvloeiende momenten bij kinderen die stotteren met meer spanning gepaard, dan bij de vlot sprekende kinderen.
              </p>
              <p>
                Stotteren is niet aangenaam. Het kan gepaard gaan met bepaalde gedachten of ideeën over het spreken en zichzelf, het kan een breed bereik aan emoties oproepen en het kan het gedrag van het kind in het algemeen en specifiek voor het spreken wijzigen. Kleuters, kinderen en (jong)volwassenen kunnen op verschillende manieren vechten tegen het stotteren, opdat het zich niet voordoet of het stotteren ophoudt. We noemen dit secundaire gedragingen, die niet steeds zichtbaar zijn voor omgeving. Een erkende stottertherapeute kan deze bijkomende gedragingen in beeld brengen. De aanwezigheid van deze bijkomende gedragingen bepaalt mee de stotterernst en de inhoud van de therapie.
              </p>


              <h4 className={classes.title}>Therapie</h4>
              <p>
                De sociaal cognitieve gedragstherapie zal ingrijpen op de drie componenten van stotteren, namelijk de gedachten, de gevoelens en het gedrag én de omgeving. Er zijn verschillende factoren die stotteren uitlokken, versterken of in stand houden. De therapie is erop gericht deze factoren in kaart te brengen en ze te beperken of weg te laten. Verder heeft de therapie als doel om het stotteren in ernst te doen afnemen: de stotterfrequentie daalt, de duur van de stotters neemt af, secundaire gedragingen worden achterwege gelaten, er ontstaan geen nieuwe gedragingen en het spreken wordt terug aangenaam! Als cliënt, ouder of partner bevindt u zich in een zeer belangrijke positie om informatie te geven en om wijzigingen aan te brengen buiten de therapieruimte. Verdere informatie over stotteren ontvangt u tijdens de therapie en de oudercursus. De specialisatie is wetenschappelijk onderbouwd en gebaseerd op de klinische praktijk, zowel in het onderzoek als de behandeling bij kleuters, kinderen, tieners, volwassenen en hun omgeving.
              </p>
              <p>
                Therapie voor stotteren wordt gegeven in individuele sessies van 30 of 60 minuten. Indien nodig zijn de ouders of de partner hierbij aanwezig.
                Het volgen van de oudercursus is verplicht en maakt een noodzakelijk onderdeel uit van de therapie.
              </p>


              <h4 className={classes.title}>Twijfelt u of uw kind stottert? </h4>
              <p>
                Om zeker te zijn of het over stotteren of normale onvloeiendheden gaan, namelijk haperingen die alle kleuters en kinderen maken als onderdeel van de taalontwikkeling, wordt aangeraden om zo snel mogelijk contact op te nemen met een stottertherapeut. Hoe sneller stotteren onderzocht en behandeld wordt, hoe groter de kans dat het stotteren zich niet negatief ontwikkelt en hoe groter de kans op volledig herstel. De therapeut bekijkt met de ouders hoe de therapie het beste wordt ingevuld, afhankelijk van de stotterernst en de leeftijd. Voor bijkomende vragen en inlichtingen kan u vrijblijvend contact opnemen.
              </p>
            </GridItem>

            <GridItem md={12} sm={12}>
              <h3 className={classes.title}>Therapeuten</h3>
              <p>
                Voor stotteren zal u onderzocht en behandeld worden door <a href="team#elke"><b>Elke Ven</b></a>.
              </p>
            </GridItem>
          </GridContainer>
      </div>
    </div>
    </div>
  )
};
