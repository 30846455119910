import React from 'react';
import { useForm, Controller } from 'react-hook-form'
import emailjs from 'emailjs-com';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Check from "@material-ui/icons/Check";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";

import contactStyle from "assets/jss/material-kit-pro-react/pages/landingPageSections/contactStyle.js";

const useStyles = makeStyles(contactStyle);

export default function SectionContact() {
  const [specialitySelect, setSpecialitySelect] = React.useState("articulatieproblemen");
  const handleSpeciality = event => {
    setSpecialitySelect(event.target.value);
  };
  const classes = useStyles();

  const [status, setStatusBase] = React.useState("");
  const setStatus = msg => setStatusBase({ msg, date: new Date() });
  const { register, handleSubmit, reset, errors, control } = useForm()
  const onSubmit = data => {
    reset()
    setStatus('success')
    emailjs.send('default_service', 'logopedie_stage', data, 'user_eJKD9KeP2adecQy8wzIyO')
    .then(res => {
      setStatus('success')
    }).catch(err => {
      setStatus('error')
    })
  }

  return (
    <div className={classes.container}>
      <div className={classes.aboutUs}>
        {status.msg == 'success' ?
              <SnackbarContent
                key={status.date}
                status={status.msg}
                message={
                  <span>
                    <b>VERZONDEN:</b> Uw aanvraag voor een stage is verzonden,
                      we sturen u zo snel mogelijk een antwoord!
                  </span>
                }
                close
                color="success"
                icon={Check}
              /> : status.msg == 'error' ?
            <SnackbarContent
              message={
                <span>
                  <b>MISLUKT:</b> Helaas, er is iets misgelopen.
                    Probeer het later opnieuw, of contacteer ons via e-mail of telefoon.
                </span>
              }
              close
              color="danger"
              icon="info_outline"
            /> : null
          }
        <GridContainer>
          <GridItem
            md={8}
            sm={8}
            className={classNames(classes.mrAuto, classes.mlAuto)}
          >
            <h2 className={classNames(classes.title, classes.textCenter)}>
              Op zoek naar een stageplaats?
            </h2>
            <h4 className={classNames(classes.description, classes.textCenter)}>
              Op dit moment zijn er geen openstaande posities voor stages.<br/>
              Maar je kan je gegevens altijd hieronder achterlaten!
            </h4>
            <form onSubmit={handleSubmit(onSubmit)}>
              <GridContainer>
                <GridItem md={4} sm={4}>
                  <CustomInput
                    labelText="Jouw naam"
                    id="contactName"
                    register={register({required: true})}
                    error={!!errors.contactName}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem md={4} sm={4}>
                  <CustomInput
                    labelText="Jouw e-mail"
                    id="contactEmail"
                    register={register({
                      required: 'Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "invalid email address"
                      }})}
                    error={!!errors.contactEmail}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                </GridItem>
                <GridItem md={4} sm={4}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.selectUnderlineRoot
                    }
                  >
                  <Controller
                    as={
                      <Select MenuProps={{ className: classes.selectMenu }}
                        classes={{select: classes.select}}
                        value={specialitySelect}
                        onChange={handleSpeciality}
                        inputProps={{
                          name: "specialitySelect",
                          id: "speciality-select"
                        }}
                      >
                        <MenuItem
                          disabled
                          value=""
                          classes={{
                            root: classes.selectMenuItem
                          }}
                        >
                          Specialiteit
                        </MenuItem>

                        <MenuItem value="articulatieproblemen"
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}>
                          Articulatieproblemen
                        </MenuItem>

                        <MenuItem value="afwijkende mondgewoonten"
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                        >
                          Afwijkende mondgewoonten
                        </MenuItem>
                        <MenuItem value="Taalstoornissen"
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                        >
                          Taalstoornissen
                        </MenuItem>
                        <MenuItem value="stotteren"
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                        >
                          Stotteren
                        </MenuItem>
                        <MenuItem value="broddelen"
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                        >
                          Broddelen
                        </MenuItem>
                        <MenuItem value="neurogene taal-spraakstoornissen"
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                        >
                          Neurogene taal-spraakstoornissen
                        </MenuItem>
                        <MenuItem value="leerproblemen"
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                        >
                          Leerproblemen
                        </MenuItem>
                        <MenuItem value="autismestoornissen"
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected
                          }}
                        >
                          Autismestoornissen
                        </MenuItem>
                      </Select>
                    }
                    name="contactSpeciality"
                    rules={{ required: "this is required" }}
                    control={control}
                    defaultValue=""
                  />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem
                  md={4}
                  sm={4}
                  className={classNames(
                    classes.mrAuto,
                    classes.mlAuto,
                    classes.textCenter
                  )}
                >
                  <Button color="primary" round type="submit">
                    Laat ons iets weten
                  </Button>
                </GridItem>
              </GridContainer>
            </form>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
