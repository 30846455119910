import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Speech from "@material-ui/icons/RecordVoiceOver"
import Learn from "@material-ui/icons/School"
import Type from "@material-ui/icons/Keyboard"
// import Parents from "@material-ui/icons/SupervisorAccount"

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

import targetsStyle from "assets/jss/material-kit-pro-react/pages/landingPageSections/targetsStyle.js";

import bg9 from "assets/img/examples/office2.jpg";

const useStyles = makeStyles(targetsStyle);

export default function SectionTargets() {
  const classes = useStyles();
  return (
    <div
      className={classes.targets}
      style={{ backgroundImage: `url(${bg9})` }}
    >
      <GridContainer>
        <GridItem
          xs={12}
          sm={8}
          md={8}
          className={
            classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
          }
        >
          <h2 className={classes.title}>Waar we je mee kunnen helpen</h2>
          <h5 className={classes.description}>
            In onze praktijk kunnen kleuters, kinderen, jongeren en volwassenen terecht voor de volgende problemen:
          </h5>
        </GridItem>
        <div className={classes.container}>
          <GridContainer className={classes.gridContainer}>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={Speech}
                title="Articulatiestoornissen"
                link="/doelgroepen#articulatie"
                description={
                  <p>
                    Onduidelijk spreken <br/>
                    Moeite met het uitspreken van bepaalde spraakklanken<br/>
                    Articulatietraining voor studenten of beroepssprekers
                  </p>
                }
                iconColor="info"
              />
            </GridItem>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={Speech}
                title="Afwijkende mondgewoonten (OMFT)"
                link="/doelgroepen#omft"
                description={
                  <p>
                    Open mondgedrag en habitueel mondademen<br/>
                    Afwijkend kauwen<br/>
                    Verkeerde slikgewoonten<br/>
                    Afwijkende tongligging in rust<br/>
                    Foutieve spreekgewoonten<br/>
                    Tongpersen<br/>
                    Duim-, vinger- en/of speenzuigen
                  </p>
                }
                iconColor="danger"
              />
            </GridItem>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={Speech}
                title="Taalstoornissen"
                link="/doelgroepen#taal"
                description={
                  <p>
                    Vertraagde spraak- en taalontwikkeling<br/>
                    Taalontwikkelingsstoornis of dysfasie
                  </p>
                }
                iconColor="primary"
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.gridContainer}>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={Speech}
                title="Stotteren"
                link="/doelgroepen#stotteren"
                description={
                  <p>

                  </p>
                }
              />
            </GridItem>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={Speech}
                title="Broddelen"
                link="/doelgroepen#broddelen"
                description={
                  <p>
                  </p>
                }
              />
            </GridItem>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={Speech}
                title="Selectief mutisme"
                link="/doelgroepen#mutisme"
                description={
                  <p>
                  </p>
                }
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.gridContainer}>
          <GridItem xs={12} sm={4} className={classes.gridItem}>
            <InfoArea
              vertical
              className={classes.infoArea5}
              icon={Speech}
              title="Neurogene taal-spraakstoornissen"
              link="/doelgroepen#neuro"
              description={
                <p>
                  Afasie<br/>
                  Dysartrie<br/>
                  Parkinson<br/>
                  Slikstoornissen
                </p>
              }
            />
          </GridItem>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={Learn}
                title="Leerstoornissen"
                link="/doelgroepen#leerstoornissen"
                description={
                  <p>
                    Problemen met lezen en dyslexie<br/>
                    Problemen met schrijven en dysorthografie<br/>
                    Problemen met rekenen en dyscalculie
                  </p>
                }
              />
            </GridItem>
            <GridItem xs={12} sm={4} className={classes.gridItem}>
              <InfoArea
                vertical
                className={classes.infoArea5}
                icon={Learn}
                title="Autismestoornissen"
                link="/doelgroepen#autisme"
                description={
                  <p>
                  </p>
                }
              />
            </GridItem>
          </GridContainer>
          <GridContainer className={classes.gridContainer}>

            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h5 className={classes.description}>
                Elke logopediste heeft haar eigen specialisaties. U komt na de eerste aanmelding steeds terecht bij de meest geschikte persoon. Wij helpen u graag verder met onderzoek, therapie of advies.
              </h5>
              <h5 className={classes.description}>
                Wij vinden duidelijke communicatie tussen ouders, andere zorgverleners en de behandelende logopediste zeer belangrijk om kwaliteitsvolle begeleiding te geven. We nodigen u dan ook uit om aanwezig te zijn tijdens behandelingen, voor een nabespreking of een overleg. Besprekingen met ouders en de school zijn noodzakelijk om de therapie af te stemmen op de noden van het kind. Ze maken dan ook een onmisbaar deel uit van de therapie. Het RIZIV voorziet geen tussenkomst voor deze besprekingen.
              </h5>
            </GridItem>
          </GridContainer>
        </div>
      </GridContainer>
    </div>
  );
}
