/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Mail from "@material-ui/icons/Email";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LogoFooter from "components/Footer/LogoFooter.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks0.js";

import ratePageStyle from "assets/jss/material-kit-pro-react/pages/ratePageStyle.js";

const useStyles = makeStyles(ratePageStyle);

export default function LinksPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Logopedie | Lieve Mutton"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        changeColorOnScroll={{
          height: 250,
          color: "dark"
        }}
        color="transparent"
      />
      <Parallax
        image={require("assets/img/examples/office5.jpg")}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.rateContent}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem md={12} sm={12}>
              <h2 className={classes.title}>Links</h2>
              <h3>Links naar informatieve websites</h3>
              <ul>
                  <li> <a href="http://www.riziv.be">www.riziv.be</a> 			(informatie omtrent terugbetaling)</li>
                  <li> <a href="http://www.vvl.be">www.vvl.be</a> 			(Vlaamse vereniging voor logopedisten)</li>
              </ul>
              <h3>Leerstoornissen</h3>
              <ul>
                  <li><a href="http://www.letop.be">www.letop.be</a> 			(Informatiepunt leerstoornissen)</li>
                  <li><a href="http://www.sprankel.be">www.sprankel.be</a> 		(Vereniging voor ouders van kinderen met leerproblemen)</li>
                  <li><a href="http://www.dyslexie.nl">www.dyslexie.nl</a> 		(Informatiepunt dyslexie)</li>
                  <li><a href="http://www.ikhaatlezen.be">www.ikhaatlezen.be</a></li>
                  <li><a href="http://www.luisterpuntbibliotheek.be/">luisterpunt bibliotheek</a>		(Online bibliotheek luister cd's )</li>
                  <li><a href="https://www.sprintplus.be/nl">https://www.sprintplus.be/nl</a>	(Hulpmiddelen)</li>
                  <li><a href="http://www.sensotec.be/">Sensotec</a>			(Hulpmiddelen)</li>
                  <li>Kurzweil, WoDy			(Hulpmiddelen)</li>
              </ul>
              <h3>Autisme/ASS</h3>
              <ul>
                  <li><a href="http://www.autismevlaanderen.be/">www.autismevlaanderen.be</a> 			(Informatiepunt ASS)</li>
                  <li><a href="http://www.autismecentraal.com/public/standaard-k.asp?lang=NL&pid=181">Autisme Centraal</a></li>
                  <li><a href="http://www.participate-autisme.be/">Participate!</a></li>
              </ul>
              <h3>ADHD / ASS</h3>
              <ul>
                  <li><a href="http://www.zitstil.be">www.zitstil.be</a> 			(Informatiepunt ADHD/ADD)</li>
                  <li><a href="http://www.adhd-volwassenen.be/">ADHD-Volwassenen</a></li>
              </ul>
              <h3>Neurologische problemen</h3>
              <ul>
                  <li><a href="https://www.afasie.be/">https://www.afasie.be/</a> 	(Informatiepunt Afasie)</li>
                  <li><a href="http://www.neurocom.be/">Neurocom</a>			(Informatie over neurogene communicatiestoornissen)</li>
                  <li><a href="http://www.wablieft.be/">Wablieft</a> 			(Het nieuws in duidelijk taal)</li>

              </ul>
              <h3>Stotteren/broddelen</h3>
              <ul>
                  <li><a href="http://www.cioos.be">http://www.cioos.be</a> 	(Informatiepunt stotteren & broddelen)</li>
              </ul>
              <h3>Afwijkende mondgewoonten</h3>
              <ul>
                  <li><a href="http://www.omft.info/">OMFT</a> 	(Informatie over afwijkend mondgedrag)</li>
              </ul>
              <h3>Links naar spelletjes en oefenprogramma's</h3>
              <ul>
                  <li><a href="http://www.woordkasteel.be">www.woordkasteel.be</a> 	(Gratis programma om spelling te oefenen)</li>
                  <li><a href="http://www.rekentaal.net">www.rekentaal.net</a> 		(Online rekenoefeningen en rekenspelletjes)</li>
                  <li><a href="http://www.klokrekenen.nl">www.klokrekenen.nl</a> 		(Gratis programma om kloklezen te oefenen)</li>
                  <li><a href="http://www.maaltafels.be">www.maaltafels.be</a> 		(Gratis programma om de tafels in te oefenen)</li>
                  <li><a href="http://www.juf-hannah.nl">www.juf-hannah.nl</a> 		(Gratis programma om rekenen en spelling in te oefenen)</li>
                  <li><a href="http://www.rekenweb.nl">www.rekenweb.nl</a> 		(Gratis programma om rekenen in te oefenen)</li>
                  <li><a href="http://www.sommenplaneet.nl">www.sommenplaneet.nl</a> 	(Gratis programma om sommen in te oefenen)</li>
              </ul>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div className={classes.empty}/>
      <LogoFooter />
    </div>
  );
}
