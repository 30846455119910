import {
  mrAuto,
  mlAuto,
  container,
  coloredShadow,
  title,
  cardTitle,
  description
} from "assets/jss/material-kit-pro-react.js";

import imageStyles from "assets/jss/material-kit-pro-react/imagesStyles.js";

import rotatingCards from "assets/jss/material-kit-pro-react/rotatingCards.js";

const styles = {
  mlAuto,
  mrAuto,
  container,
  coloredShadow,
  title,
  cardTitle,
  ...imageStyles,
  ...rotatingCards,
  description: {
    ...description,
    marginBottom: "80px"
  },
  team: {
    padding: "80px 0px"
  },
  textCenter: {
    textAlign: "center"
  },
  cardCategory: {
    marginTop: "10px",
    "& svg": {
      position: "relative",
      top: "8px"
    }
  },
  cardDescription: {
    ...description,
  },
  bold: {
    textAlign: 'left',
    fontWeight: "bold"
  }
};

export default styles;
