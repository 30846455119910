/*eslint-disable*/
import React from "react";
import { useForm } from 'react-hook-form'
import emailjs from 'emailjs-com';
// nodejs library that concatenates classes
import classNames from "classnames";
// react components used to create a google map
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Mail from "@material-ui/icons/Email";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
import Check from "@material-ui/icons/Check";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import LogoFooter from "components/Footer/LogoFooter.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";

// sections for this page
import HeaderLinks from "components/Header/HeaderLinks0.js";

import contactPageStyle from "assets/jss/material-kit-pro-react/pages/contactPageStyle.js";

const useStyles = makeStyles(contactPageStyle);

const CustomSkinMap = withScriptjs(
  withGoogleMap(() => (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: 50.883757, lng: 4.748383 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: 50.883757, lng: 4.748383 }} />
    </GoogleMap>
  ))
);


export default function ContactPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();

  const [status, setStatusBase] = React.useState("");
  const setStatus = msg => setStatusBase({ msg, date: new Date() });
  const { register, handleSubmit, reset, errors } = useForm()
  const onSubmit = data => {
    reset()
    emailjs.send('default_service', 'logopedie_afspraak', data, 'user_eJKD9KeP2adecQy8wzIyO')
    .then(res => {
      setStatus('success')
  	}).catch(err => {
      setStatus('error')
    })
  }

  return (
    <div>
      <Header
        brand="Logopedie | Lieve Mutton"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        color="dark"
      />
      <div className={classes.bigMap}>
        <CustomSkinMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBOlByNgBx4lYxwcOevceq-O4Z0OlNDTiw"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: `100%`,
                borderRadius: "6px",
                overflow: "hidden"
              }}
            />
          }
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.contactContent}>
        {status.msg == 'success' ?
              <SnackbarContent
                key={status.date}
                status={status.msg}
                message={
                  <span>
                    <b>VERZONDEN:</b> Uw aanvraag voor een afspraak is verzonden,
                      we sturen u zo snel mogelijk een antwoord!
                  </span>
                }
                close
                color="success"
                icon={Check}
              /> : status.msg == 'error' ?
            <SnackbarContent
              message={
                <span>
                  <b>MISLUKT:</b> Helaas, er is iets misgelopen.
                    Probeer het later opnieuw, of contacteer ons via e-mail of telefoon.
                </span>
              }
              close
              color="danger"
              icon="info_outline"
            /> : null
          }
          <div className={classes.container}>
            <h2 className={classes.title}>Maak een afspraak</h2>
            <GridContainer>
              <GridItem md={6} sm={6}>
                <p>
                  De eerste afspraak vindt steeds plaats in de praktijk.
                  Daarna bekijken we wat voor de patiënt het meest geschikt is.
                  <br />
                  <br />
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <h6> Gegevens van de contactpersoon </h6>
                  <CustomInput
                    labelText="Naam en voornaam"
                    id="contactName"
                    register={register({required: true})}
                    error={!!errors.contactName}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Adres"
                    id="contactAddress"
                    register={register({required: true})}
                    error={!!errors.contactAddress}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Telefoonnummer"
                    id="contactPhone"
                    register={register({required: true})}
                    error={!!errors.contactPhone}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="E-mailadres"
                    id="contactEmail"
                    register={register({
                      required: 'Required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "invalid email address"
                      }})}
                    error={!!errors.contactEmail}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />

                  <h6> Gegevens van de betrokkene </h6>
                  <CustomInput
                    labelText="Naam en voornaam"
                    id="patientName"
                    register={register({required: true})}
                    error={!!errors.patientName}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Leeftijd"
                    id="patientAge"
                    register={register({required: true})}
                    error={!!errors.patientAge}
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
                  <CustomInput
                    labelText="Omschrijving van de vraag of van het probleem"
                    id="remarks"
                    register={register({required: true})}
                    error={!!errors.remarks}
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      multiline: true,
                      rows: 6
                    }}
                  />
                  <div className={classes.textCenter}>
                    <Button color="primary" round type="submit">
                      Maak een afspraak
                    </Button>
                  </div>
                </form>
              </GridItem>
              <GridItem md={5} sm={5} className={classes.mlAuto}>
                <h6>Wat zijn de mogelijkheden?</h6>
                <ul>
                  <li><b>In de praktijk</b> <br/> Consultatie van een logopediste is enkel mogelijk na afspraak. </li>
                  <li><b>Huisbezoeken</b><br/>Patiënten die moeilijk mobiel zijn, bieden we therapie aan huis. We rekenen wel steeds een vaste kost voor vervoer en reistijd.</li>
                  <li><b>Schoolbezoeken</b> <br/> In samenspraak met de school, kan ook therapie op school gegeven worden. Ook hiervoor rekenen we een vaste kost voor vervoer en reistijd.</li>
                </ul>

                <br/>
                <h6>Onze contactgegevens</h6>
                <InfoArea
                  className={classes.info}
                  title="Telefonisch"
                  description={
                    <p>
                      +32 498/80 75 63 <br /> Ma - Vr,
                      8u00-21u00
                    </p>
                  }
                  icon={Phone}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title="Email"
                  description={
                    <p>
                      <a href="mailto:lieve.mutton@hotmail.com"><b>lieve.mutton@hotmail.com</b></a>
                    </p>
                  }
                  icon={Mail}
                  iconColor="primary"
                />
                <InfoArea
                  className={classes.info}
                  title="Praktijk"
                  description={
                    <p>
                      Logopedie Lieve Mutton <br/> Platte Lostraat 580 A <br /> 3010 Kessel-Lo
                    </p>
                  }
                  icon={BusinessCenter}
                  iconColor="primary"
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div className={classes.empty}/>
      <LogoFooter />
    </div>
  );
}
