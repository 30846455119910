/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Mail from "@material-ui/icons/Email";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LogoFooter from "components/Footer/LogoFooter.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks0.js";

import ratePageStyle from "assets/jss/material-kit-pro-react/pages/ratePageStyle.js";

const useStyles = makeStyles(ratePageStyle);

export default function GDPRPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Logopedie | Lieve Mutton"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        changeColorOnScroll={{
          height: 250,
          color: "dark"
        }}
        color="transparent"
      />
      <Parallax
        image={require("assets/img/examples/office5.jpg")}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.rateContent}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem md={12} sm={12}>
                <h2 className={classes.title}>GDPR</h2>
                <p>
                Uw privacy wordt gewaarborgd door de GDPR.
                Deze wet beschermt uw persoonlijke gegevens en bepaalt o.a. dat u toestemming moet geven om gegevens op te vragen of door te geven aan derden (bijv. aan de leerkracht van uw kind of aan een onderzoeksinstantie).
                Uiteraard gaan wij zorgvuldig om met vertrouwelijke informatie.
                Wij vragen u altijd vooraf om toestemming om een verslag met gedetailleerde gegevens van onderzoek en behandeling te mogen versturen of een (telefonisch) overleg te plegen. Alleen met uw toestemming worden gegevens per mail verzonden.
                </p>
              </GridItem>
              <GridItem md={12} sm={12}>
                <h2 className={classes.title}>Privacyreglement</h2>
                <h3>Informatie over de verwerking van uw persoonsgegevens in onze praktijk</h3>

                <h4>Welke gegevens verzamelen we over u?</h4>
                <ul>
                  <li><p>Identificatiegegevens (en in voorkomend geval van uw wettelijke vertegenwoordiger);</p></li>
                  <li><p>Mutualiteit;</p></li>
                  <li><p>Rijksregisternummer;</p></li>
                  <li><p>Gegevens over je gezondheid (diagnose, prognose, anamnese, behandeling, …)</p></li>
                  <li><p>Evt. gGegevens over uw familieleden, enkel indien dit relevant is voor de logopedische behandeling;</p></li>
                  <li><p>Gegevens over opleiding en vorming;</p></li>
                  <li><p>Geluids- of beeldopname;</p></li>
                  <li><p>Evt. financiële gegevens.</p></li>
                </ul>

                <h4>Waarom verzamelen we die gegevens?</h4>
                <p>We verzamelen deze gegevens om u adequate en kwaliteitsvolle logopedische zorg te kunnen verlenen, om onze logopedische prestaties te kunnen aanrekenen (aan het RIZIV en eventueel aan u) en om eventuele klachten of schadegevallen te kunnen opvolgen.</p>
                <p>De gegevens worden verzameld en bewaard op grond van de behandelrelatie die er tussen u en ons als logopedist bestaat (een overeenkomst), omdat we daar wettelijk toe verplicht zijn, omdat het noodzakelijk is om uw vitale belangen of ons gerechtvaardigd belang te beschermen of op grond van uw uitdrukkelijke toestemming met die bewaring en verzameling.</p>
                <p>Gegevens over uw gezondheid worden specifiek bewaard omdat dit noodzakelijk is om u logopedische zorg te kunnen verlenen. In het uitzonderlijke geval waarin een patiënt een klacht of schadegeval zou hebben, moeten we ook gegevens over de gezondheid verwerken om de klacht of het schadegeval adequaat te kunnen opvolgen. </p>
        <p>Wanneer we gegevens van u verwerken om ons gerechtvaardigd belang te beschermen, gaat het over de bescherming van onze veiligheid en onze rechten van verdediging.</p>
        <h4>
            Worden de gegevens met anderen gedeeld?
        </h4>
        <p>De gegevens die we van en over u hebben, vallen in principe onder het beroepsgeheim. We delen ze dan ook enkel met anderen wanneer dit geoorloofd is, zoals:</p>
        <ul>
            <li><p>indien dit nodig is voor een goede zorgverlening (bv. met andere zorgverleners, zoals uw huisarts, met het CLB, met uw wettelijke vertegenwoordiger, …), of </p></li>
            <li><p>indien we daartoe op basis van regelgeving of rechtspraak verplicht zijn (bv. met de overheid, de mutualiteit), of </p></li>
            <li><p>voor de opvolging en afhandeling van evt.eventuele klachten en schadegevallen (verzekeringsmakelaar, verzekeraar, gerechtelijke overheden, …)</p></li>
            <li><p>wanneer u daarin uitdrukkelijk hebt toegestemd (bv. mededeling aan een familielid dat geen vertegenwoordiger is, aan een vriend(in), …).</p></li>
        </ul>
        <h4>
            Hoe lang worden mijn gegevens bewaard?
        </h4>
        <p>We zijn verplicht om uw dossier 30 jaar te bewaren. Dit betekent dan ook dat we uw gegevens 30 jaar bewaren.</p>
        <h4>Zijn er specifieke rechten die je kan uitoefenen over de gegevens die we van jou bewaren?</h4>
        <p>Als patiënt kan u verschillende rechten uitoefenen met betrekking tot de gegevens die we van u bewaren.</p>
        <p>Zo hebt u recht op inzage in en een kopie van het patiëntendossier dat we van u bewaren. Indien u uw patiëntendossier wil inkijken of daarvan een kopie wenst, kan u daartoe een schriftelijk verzoek aan de praktijk richten [Logopedische paktijk Lieve Mutton, Platte Lostraat 580 A, 3010 Kessel-Lo].</p>
        <p>Tevens hebt u het recht om te verzoeken dat bepaalde gegevens die onjuist in het patiëntendossier zijn opgenomen, gerectificeerd worden.</p>
        <p>Indien bepaalde gegevens werden verzameld enkel en alleen op basis van uw toestemming (en dus niet op basis van de behandelrelatie of op basis van een wettelijke verplichting), kan u die toestemming voor de verdere verwerking te allen tijde intrekken. De gegevens in het patiëntendossier worden bewaard op grond van de behandelrelatie en een wettelijke verplichting, en dus niet op grond van een toestemming.</p>
        <p>Indien u een klacht hebt specifiek over de verwerking van je persoonsgegevens, kan u die indienen bij de Gegevensbeschermingsautoriteit. </p>
        <h4>Wie is verantwoordelijk voor de verzameling en bewaring van je gegevens?</h4>
        <p>De verantwoordelijke voor de verzameling van uw gegevens in het logopedisch patiëntendossier is uw behandelende logopedist, die alle gegevens in het dossier noteert. De verantwoordelijke voor de bewaring ervan is de logopedische (groeps)praktijk: Logopedische praktijk Lieve Mutton, Platte Lostraat 580 A, 3010 Kessel-Lo.</p>
        <p>Op datum van het opstellen van deze privacy policy is de Gegevensbeschermingsautoriteit nog niet opgericht en zijn er nog geen contactgegevens beschikbaar.</p>

              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <div className={classes.empty}/>
      <LogoFooter />
    </div>
  );
}
