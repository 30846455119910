import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionLanguage({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section"} {...rest} >
      <div className={classes.targetContent}>
      <div className={classes.container}>
        <h2 className={classes.title}>Taalstoornissen</h2>
        <GridContainer className={classes.description}>
          <GridItem md={4} sm={4}>
            <h3 className={classes.title}>Samengevat</h3>
            <p>
              De taalontwikkeling verloopt in verschillende stadia. Bij een aantal kinderen kent deze ontwikkeling een vertraagd of afwijkend verloop. We zien dan problemen in de ontwikkeling van de taalvorm, zoals foutieve verbuigingen, vervoegingen of zinsbouw, de taalinhoud, zoals woordenschat, of het taalgebruik, zoals verhalen vertellen, of we bemerken fonologische spraakproblemen.
            </p>
          </GridItem>

          <GridItem md={8} sm={8}>
            <h3 className={classes.title}></h3>
            <h4 className={classes.title}>Soorten taalstoornissen</h4>
            <h6 className={classes.title}>Vertraagde spraak- en taalontwikkeling</h6>
            <p>
              We spreken van een vertraagde ontwikkeling wanneer het taalniveau overeenkomt met het taalniveau van een jonger persoon.
            </p>
            <h6 className={classes.title}>Vertraagde spraak- en taalontwikkeling</h6>
            <p>
              Er is sprake van dysfasie wanneer er ernstige expressieve (productie) en/of receptieve (begrip) taalstoornissen zijn, die hardnekkig blijven voortduren na de vijfde verjaardag en die ernstig interfereren met de sociale communicatie en/f dagelijkse activiteiten die mondelinge taal vereisen. De taalstoornis is niet het gevolg van een pervasieve ontwikkelingsstoornis, een gehoorstoornis (> 40 dB beste oor), of een intelligentiestoornis (PIQ &lt; 85).
            </p>
            <h6 className={classes.title}>Gestoorde taalontwikkeling</h6>
            <p>
              Hierbij vertoont de taal van een persoon kenmerken die niet thuishoren in een bepaalde fase van het taalverwervingsproces.
            </p>


            <h4 className={classes.title}>Therapie</h4>
            <p>
              Wanneer de taalproblematiek goed in kaart gebracht is, zal gerichte therapie worden opgestart. De behandeling van de verstoorde componenten (vb. woordenschat of zinsbouw) wordt volledig op maat gedaan. Geen enkele taalproblematiek is hetzelfde.
            </p>
            <p>
              Een logopedische behandeling is indirect of direct. Bij een indirecte therapie begeleidt de logopedist de ouders in de manier waarop ze het kind tot spreken kunnen stimuleren. Bij de directe logopedische behandeling werkt de logopedist individueel met het kind.
            </p>
            <p>
              Een logopedist traint het taalbegrip en verbetert het luistergedrag; er wordt gewerkt aan de woordenschat, de zinsbouw en de uitspraak. Bij kinderen die nog niet of nauwelijks spreken krijgen de voorwaarden om tot spreken te komen aandacht: het gebruiken van taal voor een bepaald doel, het imiteren van een ander, het oogcontact, beurtneming. De ouders worden zoveel mogelijk bij de behandeling betrokken. Ook is het belangrijk om de school van het kind te betrekken. De logopedist zal overleggen met het leerkrachtenteam en tips geven om ook op school gepast met het kind te werken.
            </p>
          </GridItem>

          <GridItem md={12} sm={12}>
            <h3 className={classes.title}>Therapeuten</h3>
            <p>
              Voor taalstoornissen zal u onderzocht en behandeld worden door <a href="team#lieve"><b>Lieve Mutton</b></a>.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
    </div>
  )
};
