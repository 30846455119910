import {
  container,
  cardTitle,
  title,
  description,
  main,
  mainRaised,
  mrAuto,
  whiteColor,
  mlAuto,
  section,
  grayColor,
} from "assets/jss/material-kit-pro-react.js";

const targetPageStyle = {
  main,
  mainRaised,
  cardTitle,
  title,
  mrAuto,
  mlAuto,
  parallax: {
    height: "380px",
    backgroundPosition: "top center"
  },
  empty: {
    height: "100px"
  },
  container: {
    ...container,
    zIndex: 1
  },
  info: {
    paddingBottom: "10px",
    paddingTop: 0
  },
  textCenter: {
    textAlign: "center !important"
  },
  justifyContentCenter: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  },
  block: {
    color: "inherit",
    padding: "0.9375rem",
    fontWeight: "500",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px",
    width: "auto"
  },
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  left: {
    float: "left!important",
    display: "block"
  },
  right: {
    padding: "15px 0",
    margin: "0",
    float: "right"
  },
  icon: {
    width: "18px",
    height: "18px",
    top: "3px",
    position: "relative"
  }
};

export default targetPageStyle;
