/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
import PinDrop from "@material-ui/icons/PinDrop";
import Phone from "@material-ui/icons/Phone";
import Mail from "@material-ui/icons/Email";
import BusinessCenter from "@material-ui/icons/BusinessCenter";
// core components
import Header from "components/Header/Header.js";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import LogoFooter from "components/Footer/LogoFooter.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks0.js";
import SectionArticulation from "./Sections/SectionArticulation.js";
import SectiomOMFT from "./Sections/SectionOMFT.js";
import SectionLanguage from "./Sections/SectionLanguage.js";
import SectionLearning from "./Sections/SectionLearning.js";
import SectionStutter from "./Sections/SectionStutter.js";
import SectionClutter from "./Sections/SectionClutter.js";
import SectionNeuro from "./Sections/SectionNeuro.js";
import SectionAutisme from "./Sections/SectionAutisme.js";
import SectionMutisme from "./Sections/SectionMutisme.js";

import targetPageStyle from "assets/jss/material-kit-pro-react/pages/targetPageStyle.js";

const useStyles = makeStyles(targetPageStyle);

export default function TargetPage() {
  React.useEffect(() => {
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#") + 1
    );
    if (window.location.href.lastIndexOf("#") > 0) {
      document.getElementById(href).scrollIntoView();
    }
    window.addEventListener("scroll", updateView);
    updateView();
    return function cleanup() {
      window.removeEventListener("scroll", updateView);
    };
  });
  const updateView = () => {
    var contentSections = document.getElementsByClassName("cd-section");
    var navigationItems = document
      .getElementById("cd-vertical-nav")
      .getElementsByTagName("a");

    for (let i = 0; i < contentSections.length; i++) {
      var activeSection =
        parseInt(navigationItems[i].getAttribute("data-number")) - 1;

      if (
        contentSections[i].offsetTop - window.innerHeight / 2 <
          window.pageYOffset &&
        contentSections[i].offsetTop +
          contentSections[i].scrollHeight -
          window.innerHeight / 2 >
          window.pageYOffset
      ) {
        navigationItems[activeSection].classList.add("is-selected");
      } else {
        navigationItems[activeSection].classList.remove("is-selected");
      }
    }
  };
  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };
  const smoothScroll = target => {
    var targetScroll = document.getElementById(target);
    scrollGo(document.documentElement, targetScroll.offsetTop+250, 1250);
  };
  const scrollGo = (element, to, duration) => {
    var start = element.scrollTop,
      change = to - start,
      currentTime = 0,
      increment = 20;

    var animateScroll = function() {
      currentTime += increment;
      var val = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) {
        setTimeout(animateScroll, increment);
      }
    };
    animateScroll();
  };
  const classes = useStyles();
  return (
    <div>
      <Header
        brand="Logopedie | Lieve Mutton"
        links={<HeaderLinks dropdownHoverColor="primary" />}
        fixed
        changeColorOnScroll={{
          height: 250,
          color: "dark"
        }}
        color="transparent"
      />
      <Parallax
        image={require("assets/img/examples/office5.jpg")}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <SectionArticulation id='articulatie'/>
        <SectiomOMFT id='omft'/>
        <SectionLanguage id='taal'/>
        <SectionStutter id='stotteren'/>
        <SectionClutter id='broddelen'/>
        <SectionMutisme id='mutisme'/>
        <SectionNeuro id='neuro'/>
        <SectionLearning id='leerstoornissen'/>
        <SectionAutisme id='autisme'/>
      </div>
      <nav id="cd-vertical-nav">
        <ul>
          <li>
            <a
              href="#articulatie"
              data-number="1"
              className="is-selected"
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("articulatie");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Articulatiestoornissen</span>
            </a>
          </li>
          <li>
            <a
              href="#omft"
              data-number="2"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("omft");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Afwijkende mondgewoonten</span>
            </a>
          </li>

          <li>
            <a
              href="#taal"
              data-number="3"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("taal");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Taalstoornissen</span>
            </a>
          </li>

          <li>
            <a
              href="#stotteren"
              data-number="4"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("stotteren");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Stotteren</span>
            </a>
          </li>

          <li>
            <a
              href="#broddelen"
              data-number="5"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("broddelen");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Broddelen</span>
            </a>
          </li>

          <li>
            <a
              href="#mutisme"
              data-number="9"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("mutisme");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Selectief mutisme</span>
            </a>
          </li>

          <li>
            <a
              href="#neuro"
              data-number="6"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("neuro");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Neurogene taal-spraakstoornissen</span>
            </a>
          </li>

          <li>
            <a
              href="#leerstoornissen"
              data-number="7"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("leerstoornissen");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Leerstoornissen</span>
            </a>
          </li>

          <li>
            <a
              href="#autisme"
              data-number="8"
              className=""
              onClick={e => {
                var isMobile = navigator.userAgent.match(
                  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
                );
                if (isMobile) {
                  // if we are on mobile device the scroll into view will be managed by the browser
                } else {
                  e.preventDefault();
                  smoothScroll("autisme");
                }
              }}
            >
              <span className="cd-dot" />
              <span className="cd-label">Autismestoornissen</span>
            </a>
          </li>

        </ul>
      </nav>
      <div className={classes.empty}/>
      <LogoFooter />
    </div>
  );
}
