/*!

=========================================================
* Material Kit PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router";

import "assets/scss/material-kit-pro-react.scss?v=1.8.0";

// pages for this product
import LinksPage from "pages/LinksPage/LinksPage.js"
import GDPRPage from "pages/GDPRPage/GDPRPage.js"
import ContactPage from "pages/ContactPage/ContactPage.js"
import RatePage from "pages/RatePage/RatePage.js"
import TargetPage from "pages/TargetPage/TargetPage.js"
import ProfilePage from "pages/ProfilePage/ProfilePage.js"
import LandingPage from "pages/LandingPage/LandingPage.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/links" component={LinksPage} />
      <Route path="/gpdr" component={GDPRPage} />
      <Route path="/contact" component={ContactPage} />
      <Route path="/tarieven" component={RatePage} />
      <Route path="/doelgroepen" component={TargetPage} />
      <Route path="/team" component={ProfilePage} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
