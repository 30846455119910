import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionNeuro({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section "} {...rest} >
      <div className={classes.targetContent}>
      <div className={classes.container}>
        <h2 className={classes.title}>Neurogene taal-spraakstoornissen</h2>
        <GridContainer>
          <GridItem md={4} sm={4}>
            <h3 className={classes.title}>Samengevat</h3>
            <p>
              In onze praktijk kunnen personen terecht met spraak- en taalproblemen na een niet-aangeboren hersenletsel, zoals afasie, dysartrie en apraxie. De therapie kan plaatsvinden in de praktijk, aan huis of in het woonzorgcentrum.
            </p>
            <p>
              Spraak- en slikproblemen, ook wel dysartrie en dysfagie genoemd, ten gevolge van neurodegeneratieve aandoeningen kunnen de levenskwaliteit van een persoon sterk beïnvloeden. Wij begeleiden deze patiënten in de praktijk of aan huis.
            </p>
          </GridItem>

          <GridItem md={8} sm={8}>
            <h3 className={classes.title}></h3>
            <h4 className={classes.title}>Wat zijn neurogene stoornissen?</h4>
            <p>
              Personen met een niet-aangeboren hersenletsel, bijvoorbeeld een hersenbloeding, beroerte of een tumor,
              kunnen hierna spraak- en taalproblemen ondervinden.
              Afhankelijk van de grootte, de aard en de ligging van het letsel, onderscheiden we verschillende stoornissen die geïsoleerd of samen kunnen voorkomen in verschillende ernstgraden.
            </p>
            <p>
              Problemen op het gebied van taalbegrip en/of taalproductie noemen we afasie. Woordvindingsproblemen treden vaak op bij deze stoornis.
              Dysartrie is een spraakstoornis die o.a. gekenmerkt kan worden door een slappe en/of onnauwkeurige articulatie, een te snel/traag spreektempo, onaangepaste klemtonen, een onaangepaste luidheid, ed. Dit resulteert vaak in  een verminderde spraakverstaanbaarheid.
              Personen met spraakapraxie hebben problemen met het plannen van de verschillende spraakklanken. Deze personen vertonen vaak ‘zoekgedrag’ tijdens het spreken en maken inconsistente fouten.
            </p>

            <h4 className={classes.title}>Wat zijn neurodegeneratieve aandoeningen?</h4>
            <p>
              Neurodegeneratieve aandoeningen zoals Parkinson, ALS, MS, MSA, PSP, ed. zijn ziektes waarbij het zenuwstelsel aangetast wordt. Hierdoor kunnen er spraak- en/of slikproblemen ontstaan. De logopedist brengt deze problemen in kaart. De klachten kunnen zeer uiteenlopend zijn en ieder ziekteverloop is erg individueel.
            </p>

            <h4 className={classes.title}>Therapie</h4>
            <h6 className={classes.title}>Neurogene stoornissen</h6>
            <p>
              Alle stoornissen hebben een verstoorde communicatie tussen de patiënt en zijn omgeving als gevolg. De therapie beoogt het herstellen van deze communicatie en het vergroten van de zelfstandigheid van de patiënt. De prognose is sterk afhankelijk van de aard van het letsel, de grootte en de ligging van het getroffen gebied en het ontstaan van het letsel en de leeftijd van de patiënt. Hoe jonger de hersenen, hoe plastischer deze zijn en hoe sneller men herstel kan verwachten. Het is bovendien erg belangrijk om deze patiënten te behandelen in de kritische periode, dit wil zeggen kort na het ontstaan van het letsel. De therapie bestaat uit verschillende onderdelen, namelijk revalidatie, consolidatie en compensatie. In de eerste plaats zal men de verloren functies trachten te herstellen door het aanleren van specifieke technieken. Vervolgens zal men deze technieken inslijpen en leren gebruiken in het dagelijkse leven. Het is mogelijk dat bepaalde functies onherstelbaar zijn of zeer ernstig gestoord zijn. Eventueel kan er dan een tijdelijk of permanent compenserend hulpmiddel worden ingeschakeld. Samen met de patiënt exploreert de logopedist de mogelijkheden en wordt er gezocht naar het meest geschikte communicatiehulpmiddel. Dit noemt met ook wel ondersteunde communicatie.
            </p>

            <h6 className={classes.title}>Neurodegeneratieve aandoeningen</h6>
            <p>
              De therapie focust onder andere op het aanleren van wetenschappelijk onderbouwde spraak- en sliktechnieken met als doel het zo lang mogelijk behouden van de resterende spraak- en slikfunctie. Zo leren personen met Parkinson luid en laag spreken door specifieke technieken, namelijk LSVT en PLVT. Naarmate de ziekte vordert, nemen de spraak- en slikklachten meestal toe. Indien het risico op verslikken dermate groot is, worden er compenserende technieken toegepast en worden er adviezen gegeven om het slikken toch veilig te laten verlopen, zoals bijvoorbeeld het indikken van vloeistoffen. Wanneer de spraakfunctie ernstig gestoord is, zal er gezocht worden naar alternatieve communicatietechnieken om de communicatie tussen de patiënt en zijn omgeving te behouden. De therapie heeft als doel de levenskwaliteit van de patiënt te optimaliseren en zijn zelfstandigheid zo lang mogelijk te behouden.
            </p>

          </GridItem>

          <GridItem md={12} sm={12}>
            <h3 className={classes.title}>Therapeuten</h3>
            <p>
              Voor neurogene taal-spraakstoornissen zal u onderzocht en behandeld worden
              door <a href="team#lieve"><b>Lieve Mutton</b></a> en <a href="team#madou"><b>Madou Parys</b></a>.
            </p>
            <p>
              Voor neurodegeneratieve aandoeningen door <a href="team#madou"><b>Madou Parys</b></a>.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  </div>
  )
};
