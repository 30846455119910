/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";
import Muted from "components/Typography/Muted.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";

import lieve from "assets/img/profile/lieve_square.jpg";

import profilePageStyle from "assets/jss/material-kit-pro-react/pages/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function ProfileLieve() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <div className={classes.profile}>
            <div>
              <img src={lieve} alt="..." className={imageClasses} />
            </div>
            <div className={classes.name}>
              <h3 className={classes.title}>Lieve Mutton</h3>
              <h6>Coördinator en behandelende logopediste</h6>
            </div>
          </div>
        </GridItem>
      </GridContainer>
      <div className={classes.profileTabs}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            className={classes.gridItem}>
            <h4 className={classes.title}>Achtergrond</h4>
            <p>
            Lieve Mutton is logopediste en coördinator van de logopedische praktijk. Daarnaast werkt ze ook in een Centrum voor Ambulante Revalidatie (CAR Zeplin te Lovenjoel), waar ze betrokken is bij de multidisciplinaire zorg voor kinderen met ASS, AD(H)D en complexe ontwikkelingsstoornissen</p>
            <p>Als erkend lid van de Vlaamse Vereniging van Logopedisten (VVL) zet ze zich in voor de hoogst mogelijke kwaliteit van logopedische zorg. Ook recenseert ze voor het vakblad van de beroepsvereniging, zodat ze de nieuwste inzichten en ontwikkelingen binnen ons vakgebied kan delen met collega’s.</p>
            <p>Ze is ook stagebegeleider voor toekomstige logopedisten (KU Leuven). Lieve gelooft dat het essentieel is om continu bij te leren en blijft daarom op de hoogte van de meest recente wetenschappelijk onderbouwde methodes en benaderingen door het volgen van studiedagen, congressen en bijscholingen.</p>
            <p>Bij ons staat persoonlijke aandacht en effectieve begeleiding voorop. Ze kijkt ernaar uit om samen met u of uw kind aan de slag te gaan!</p>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={5}
            className={classes.gridItem}>
            <h4 className={classes.title}>Vakgebieden</h4>
            <ul className={classes.job}>
              <li>
                <a href='doelgroepen#articulatie'>Articulatiestoornissen</a>
              </li>
              <li>
                <a href='doelgroepen#omft'>Afwijkende mondgewoonten (OMFT)</a>
              </li>
              <li>
                <a href='doelgroepen#taal'>Taalstoornissen</a>
              </li>
            </ul>
            <hr />
            <h4 className={classes.title}>Opleidingen</h4>
            <ul>
              <li>Master logopedische en audiologische wetenschappen (KU Leuven, 2008)</li>
              <li>Academische lerarenopleiding Gezondheidswetenschappen (KU Leuven, 2009)</li>
              <li>Specialisatie Oromyofunctionele stoornissen (2016 & 2018; P. Helderop)</li>
              <li>Specialisatie Hodson & Paden (2022, SIG)</li>
              <li>Jasper therapeut (i.o., 2023-2024)</li>
              <li>Jaarlijks volgt Lieve nog kleinere congressen, bijscholingen en studiedagen</li>
            </ul>
            <hr />
          </GridItem>
        </GridContainer>

      </div>
      <Clearfix />
    </div>
    </div>
  );
}
