import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionLearning({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section " + classes.sectionDark} {...rest} >
      <div className={classes.targetContent}>
      <div className={classes.container}>
        <h2 className={classes.titleWhite}>Leerstoornissen</h2>
          <GridContainer className={classes.descriptionWhite}>
            <GridItem md={4} sm={4}>
              <h3 className={classes.title}>Samengevat</h3>
              <p>
                Kinderen kunnen moeilijkheden ervaren met leren lezen, schrijven en rekenen. Wanneer kinderen een achterstand hebben op één of meerdere van deze schoolse vaardigheden, kan een logopedist hulp bieden. Wanneer de moeilijkheden aanwezig blijven ondanks intensieve remediëring, kan er sprake zijn van een leerstoornis. We spreken dan over dyslexie bij moeilijkheden met lezen, dysorthografie bij moeilijkheden bij spelling en dyscalculie bij moeilijkheden met rekenen.
              </p>
            </GridItem>

            <GridItem md={8} sm={8}>
              <h3 className={classes.title}></h3>
              <h4 className={classes.title}>Wat zijn leerstoornissen?</h4>
              <h6 className={classes.title}>Dyslexie</h6>
              <p>
                Leerlingen met dyslexie vertonen opvallende en blijvende moeilijkheden met lezen en/of spelling . De fouten die zij maken lijken op verstrooidheidsfouten. Bijvoorbeeld: ‘kineren’  in plaats van ‘kinderen’ schrijven. Zij lezen hun vragen dikwijls verkeerd en antwoorden dan ook fout of onvolledig. Bij vreemde talen geeft dit heel wat problemen.
              </p>
              <h6 className={classes.title}>Dyscalculie</h6>
              <p>
                Leerlingen met dyscalculie vertonen opvallende en blijvende moeilijkheden met rekenen en wiskunde en dit op het vlak van automatiseren of geheugendyscalculie, vaardigheden en technieken of procedurele dyscalculie en/of problemen met visuo-spatiële deeltaken van het rekenen of visuo-motorische dyscalculie. Dyscalculie kan samengaan met  andere leerproblemen als ADHD en dyslexie.
              </p>
              <h6 className={classes.title}>Dysorthografie</h6>
              <p>
                Leerlingen met dysorthografie vertonen opvallende en blijvende moeilijkheden met spelling en dit op vlak van automatiseren van spellingsregels of onthoudwoorden.
              </p>

              <h4 className={classes.title}>Therapie</h4>
              <p>
                Bij de behandeling van leerstoornissen wordt er gezocht naar de methode die het meeste resultaat heeft op lange termijn voor het kind. Er kan hierbij bijvoorbeeld gebruik gemaakt worden van de methoden van Eureka-onderwijs gebaseerd en gericht op kinderen met leerstoornissen, zoals Rekentrappers met o.a. cirkelrekenen of  Als spelling een kwelling is.
              </p>

              <h4 className={classes.title}>Attest</h4>
              <p>
                Indien de moeilijkheden bij lezen, spelling of rekenen hardnekkig zijn ondanks intensieve logopedische begeleiding, kan er een attest worden gemaakt. Het opstellen van een attest dyslexie, dysorthografie of dyscalculie gebeurt steeds na  een intensieve logopedische begeleiding van ongeveer 12 maanden en in overleg met de school en het CLB. De verschillende ontwikkelingsdomeinen worden hierbij in beeld gebracht, om andere oorzaken voor de moeilijkheden uit te sluiten en om de behandeling verder af te stellen op de noden van het kind.
              </p>

            </GridItem>

            <GridItem md={12} sm={12}>
              <h3 className={classes.title}>Therapeuten</h3>
              <p>
                Voor leerstoornissen zal u onderzocht en behandeld worden
                door <a href="team#lieve"><b>Lieve Mutton</b></a> en <a href="team#madou"><b>Madou Parys</b></a>.
              </p>
            </GridItem>
          </GridContainer>
      </div>
    </div>
  </div>
  )
};
