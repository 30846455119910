import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionOMFT({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section " + classes.sectionDark} {...rest} >
      <div className={classes.targetContent}>
        <div className={classes.container}>
          <h2 className={classes.titleWhite}>Afwijkende mondgewoonten (OMFT)</h2>
            <GridContainer className={classes.descriptionWhite}>
              <GridItem md={4} sm={4}>
                <h3 className={classes.title}>Samengevat</h3>
                <p>
                  Bij afwijkende mondgewoonten zijn er verkeerde gedragingen ontstaan in de lippen, tong en kaak. Het evenwicht van de betrokken mondspieren is hierbij verstoord, wat een negatieve invloed kan hebben op de vorm en functie van kaken en tanden/kiezen. Tot de afwijkende mondgewoonten behoren open mondgedrag en mondademen, afwijkend kauwen, verkeerde slikgewoonten, afwijkende tongligging in rust, foutieve spreekgewoonten en duim-, vinger- of speenzuigen.
                </p>
              </GridItem>

              <GridItem md={8} sm={8}>
                <h3 className={classes.title}></h3>
                <h4 className={classes.title}>Wat zijn afwijkende mondgewoonten?</h4>
                <h6 className={classes.title}>Open mondgedrag en mondademen</h6>
                <p>
                  Normaal gesproken is de mond gesloten, rust de tongpunt tegen het gehemelte achter de boventanden en wordt er door de neus geademd. Wanneer de mond gedurende de  dag regelmatig open is in rust, is er sprake van open mondgedrag. Vaak ligt de tong dan slap op de mondbodem en drukt de tong met grote kracht tegen de tanden aan. Hierdoor worden de tanden naar voren geduwd. Daarnaast is er veelal sprake van slappe mondspieren en mondademen. Bij mondademen is de gewoonte ontstaan om door de mond te ademen terwijl de neus voldoende doorgankelijk is. Mondademen heeft eveneens een negatieve invloed op de groei van de kaak, tanden en kiezen.
                </p>
                <h6 className={classes.title}>Afwijkend kauwen</h6>
                <p>
                  Onder afwijkend kauwen wordt het smakken, het tegelijkertijd aan twee kanten kauwen en het verkeerd afbijten van voedsel verstaan. Dit kan het evenwicht van de spieren in en rond de mond verstoren.
                </p>
                <h6 className={classes.title}>Verkeerde slikgewoonten</h6>
                <p>
                  Er wordt gesproken van afwijkend slikken wanneer de tong tijdens het slikken tegen de tanden en/of kiezen aanduwt of wanneer de tong tussen de tanden en/of kiezen doorkomt (tongprotrusie of tongpersen). Op de plaats waar de tong naar buiten komt, kan een open beet ontstaan. Per dag wordt meer dan 2000 keer geslikt, waardoor het wijzigingen van dit gedrag noodzakelijk is om tot een langdurig positief effect op de tandenstand te komen. Nauwe samenwerking met de orthodontist of tandarts is hier noodzakelijk.
                </p>
                <h6 className={classes.title}>Afwijkende tongligging in rust</h6>
                <p>
                  ODe tong hoort in rust achter de boventanden tegen het gehemelte aan te liggen. Bij een afwijkende tongligging ligt de tong op de mondbodem, tegen of tussen de tanden of te ver achterin de mond. Dit kan de stand van de tanden en kiezen en/of de vorm van de kaak negatief beïnvloeden.
                </p>
                <h6 className={classes.title}>Foutieve spreekgewoonten </h6>
                <p>
                  Als de tong in rust en tijdens het slikken gewend is om de tanden te raken, zal dit ook tijdens het spreken gebeuren. Bij het uitspreken van de [l], [n], [t], [s] en [z]  zal de tong naar voren komen. De tong ligt tegen of tussen de tanden in plaats van tegen het gehemelte. Soms is dit goed hoorbaar, bijvoorbeeld bij lispelen.
                </p>
                <h6 className={classes.title}>Duim-, vinger-, of speenzuigen </h6>
                <p>
                  Van jongs af aan hebben kinderen een zuigbehoefte. Zuigen op de duim, vinger(s) of een speen geeft veel kinderen een rustgevend en veilig gevoel. Tot anderhalf jaar is de zuigbehoefte van een kind een normaal proces. Daarna moet duim-, vinger- en speenzuigen worden afgeleerd. Het langdurig zuigen kan een gewoonte worden die problemen oplevert. Wanneer een kind dit op driejarige leeftijd nog steeds doet, is er sprake van afwijkend mondgedrag. Vanaf dat moment kan het duimen, zuigen of spenen een negatieve invloed hebben op de stand van de tanden en de vorm van de kaak. Eveneens kan de kracht van de spieren in en om de mond afnemen. Als er tijdens de logopedische behandeling nog sprake is van deze gedragingen, zal er zeker aandacht aan besteed worden in therapie.
                </p>

                <h4 className={classes.title}>Oro-myofunctionele therapie voor afwijkende mondgewoonten</h4>
                <p>
                  Om de gevolgen van afwijkend mondgedrag te beperken, is het belangrijk dat er vroegtijdig behandeld wordt. De logopedische behandeling kan zowel voor, tijdens of na de wisselperiode plaatsvinden.
                </p>
                <p>
                  De logopedist zal de afwijkende mondgewoonten afleren met behulp van de oro-myofunctionele therapie (OMFT). Het doel van OMFT is het weer in evenwicht brengen van alle mondspieren door middel van oefeningen en het afleren van verkeerde gewoonten, zodat vorm en functie van kaken en tanden/kiezen weer hersteld kunnen worden.
                </p>
                <p>
                  De eerste keer bekijkt de logopedist de mondfuncties, de gebitsstand, de totale lichaamshouding en de afwijkende mondgewoonten. Daarnaast worden er metingen gedaan. Afhankelijk van de onderzoekgegevens wordt de behandeling bepaald. Na elk consult worden oefeningen meegegeven. Alleen door veelvuldig oefenen is verandering mogelijk. Doorzettingsvermogen en motivatie zijn de voorwaarden voor succes.
                </p>
                <p>
                  De behandeling omvat gemiddeld 10-13 consultaties. Dit kan individueel sterk variëren. In eerste instantie zal er wekelijks behandeling plaatsvinden. Na afloop van de behandeling vinden er nog controles en metingen plaats.
                </p>
                <p>
                  Bij het kauwen, slikken en spreken zijn veel spieren betrokken o.a. lip-, kauw-, en tongspieren. De vorm van de mond en de stand van de tanden en kiezen worden voor een groot deel bepaald door de functie van de spieren in en om de mond.
                </p>
                <p>
                  Een verkeerd gebruik van deze spieren kan volgende negatieve effecten hebben:
                  <ul>
                    <li>
                      de stand van het gebit wordt negatief beïnvloed wordt, voorafgaand aan en ook na een orthodontische behandeling
                    </li>
                    <li>
                      de articulatie incorrect is, bijvoorbeeld bij lispelen
                    </li>
                    <li>
                      er is een verhoogde kans op oorproblemen, zoals middenoorontstekingen
                    </li>
                    <li>
                      er kunnen terugkerende infecties in de keel of van de amandelen zijn
                    </li>
                  </ul>
                </p>
                <p>
                  Logopedisten zijn opgeleid om verschillende vormen van afwijkend mondgedrag af te leren en goede mondgewoonten aan te leren. Samen met de logopedist kan er een beter resultaat worden verkregen vooraf of na orthodontie. We streven dan ook naar een samenwerking tussen de logopedist, de orthodontist en tandarts voor het beste resultaat.
                </p>


                <h4 className={classes.title}>Stappen OMFT</h4>
                <p>
                  <ul>
                    <li>
                      Het afleren van afwijkend mondgedrag als duim- en vingerzuigen, speenzuigen en mondademen.
                    </li>
                    <li>
                      Het aanleren van een correcte tongpositie in rust.
                    </li>
                    <li>
                      Het aanleren van een correcte tongpositie tijdens de  slik.
                    </li>
                    <li>
                      Verbeteren van de uitspraak zoals de [l], [n], [t], [s] en [z]
                    </li>
                  </ul>
                </p>
                <p>
                  Het doel hierbij is het automatiseren van een juiste tongpositie in rust en tijdens het slikken!
                </p>
                <p>
                  Tijdens de therapie maken we soms gebruik van habitbreakers of trainers. Deze trainers of bitjes worden gebruikt om de spierkracht van de lippen te vergroten, lipsluiting te bevorderen en neusademhaling te stimuleren, de juiste tongpositie in rust te stimuleren en een correcte slik te bewerkstelligen. De trainers hebben geen direct effect op de tandenstand, al is het mogelijk dat het wijzigen van de afwijkende mondgewoonten en verbeteren van de spierkracht als indirect effect een positieve en natuurlijke wijziging van de tandenstand tot gevolg heeft.
                </p>
                <p>
                  Door deze aanpak is de kans op relaps of het opnieuw ontstaan van gebitsafwijkingen ná orthodontie of kaakchirurgie veel minder.
                </p>
              </GridItem>

              <GridItem md={12} sm={12}>
                <h3 className={classes.title}>Therapeuten</h3>
                <p>
                  Voor afwijkende mondgewoonten zal u onderzocht en behandeld worden door <a href="team#lieve"><b>Lieve Mutton</b></a>.
                </p>
              </GridItem>
            </GridContainer>
        </div>
      </div>
    </div>
  )
};
