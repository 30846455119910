import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// Core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import targetSectionStyle from "assets/jss/material-kit-pro-react/pages/targetPageSections/targetPageSectionStyle.js";

const useStyles = makeStyles(targetSectionStyle);

export default function SectionClutter({ ...rest }) {
  const classes = useStyles();
  return (
    <div className={"cd-section "} {...rest} >
      <div className={classes.targetContent}>
        <div className={classes.container}>
          <h2 className={classes.title}>Broddelen</h2>
          <GridContainer>
            <GridItem md={4} sm={4}>
              <h3 className={classes.title}>Samengevat</h3>
              <p>
                Broddelen is een stoornis in de vloeiendheid van het spreken, waarbij de spreker te snel praat. Er doen zich veel normale onvloeiendheden voor, maar ook taalfouten zoals grammaticale fouten of woorden die op een verkeerde plaats in de zin staan. De spraakverstaanbaarheid is hierdoor minder.
              </p>
            </GridItem>

            <GridItem md={8} sm={8}>
              <h3 className={classes.title}></h3>
              <h4 className={classes.title}>Wat is broddelen?</h4>
              <p>
                Broddelen is een stoornis in de vloeiendheid van het spreken, waarbij de spreker niet in staat is zijn tempo aan te passen aan de taal- of motorische eisen van het moment (Van Zaalen, 2009).
              </p>
              <p>
                De persoon die broddelt, heeft vaak een hoog spreektempo. Er komen veel normale onvloeiendheden voor en weinig stotteronvloeiendheden. Zo worden pauzes op onjuiste plaatsen gemaakt. De spraakverstaanbaarheid is ook vaak minder, doordat er lettergrepen worden weggelaten, worden omgewisseld of omdat er oppervlakkig gearticuleerd wordt. De zinnen zijn niet steeds juist geformuleerd. Hele woorden worden weglaten of van plaats veranderd. Zuiver broddelen komt voor bij 5% van de bevolking. 
              </p>

              <h4 className={classes.title}>Therapie</h4>
              <p>
                Een persoon die broddelt is zich vaak niet bewust van zijn eigen onvloeiendheden, waardoor het wijzigen van het eigen spreken moeilijk is. De therapie zal bestaan uit het creëren van bewustzijn rond de spraakmoeilijkheden en vervolgens het vertragen van het spreektempo. Dit wordt geoefend op verschillende taalniveaus.
              </p>

            </GridItem>

            <GridItem md={12} sm={12}>
              <h3 className={classes.title}>Therapeuten</h3>
              <p>
                Voor broddelen zal u onderzocht en behandeld worden door <a href="team#elke"><b>Elke Ven</b></a>.
              </p>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
};
