import {
  container,
  title,
  cardTitle,
  description,
  mlAuto,
  mrAuto,
  section,
  sectionDark,
  coloredShadow,
  blackColor,
  grayColor,
  whiteColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

const targetSection = {
  container,
  title,
  mlAuto,
  mrAuto,
  cardTitle,
  coloredShadow,
  description,
  targetContent: {
    paddingBottom: "40px",
    paddingTop: "40px"
  },
  descriptionWhite: {
    ...description
  },
  textCenter: {
    textAlign: "center"
  },
  target: {
    padding: "80px 0",
    "& h5$description,& h5$descriptionWhite": {
      marginBottom: "80px"
    }
  },
  titleWhite: {
    ...title,
    color: whiteColor + "  !important"
  },
  descriptionWhite: {
    ...description,
    color: whiteColor,
    "& $title":{
      color: whiteColor
    }
  },
  sectionDark: {
    // background: grayColor[4]
    background: "linear-gradient(90deg, rgba(44,53,0,1) 0%, rgba(119,129,24,1) 75%, rgba(192,202,48,1) 100%);"
  },
  section: {
    ...sectionDark,
    ...section,
    position: "relative",
    "& $title": {
      color: whiteColor
    },
    "& $descriptionWhite": {
      color: "rgba(" + hexToRgb(whiteColor) + ", 0.76)"
    },
    "& $container": {
      zIndex: "2",
      position: "relative"
    },
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.7)"
    }
  },
  justifyContent: {
    WebkitBoxPack: "center !important",
    MsFlexPack: "center !important",
    justifyContent: "center !important"
  }
};

export default targetSection;
